.App {
    text-align: center;
    -webkit-user-select: none;
  
  }
  

  body{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    overscroll-behavior:contain
  }
  
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  
  label.col-form-label{
    text-align: right
  }
  
  
  .form-control.line{
    color : blue;
  
    /* border-radius : 0;
    border: 0px;
    border-bottom: 1px solid #ced4da; */
  }
  
  
  .form-control{
    color : blue;
  /* 
    border-radius : 0;
    border: 0px;
      border-bottom: 1px solid #ced4da; */
  }
  
  .checked {
    color: orange;
  }
 
  @media (min-width:  300px) { 
    .fa-star {
      font-size: 1.4em;
      margin-left: 10px
    }
  
  
   }

   @media (min-width: 576px) { 

 
    .fa-star {
      font-size: 1.4em;
      margin-left: 16px
    }
    }

  
  .top-container {
    background-color: #f1f1f1;
    padding: 30px;
    text-align: center;
    color: white;
    
    background-image: url("https://images.wallpaperscraft.com/image/forest_trees_nature_79434_1280x1024.jpg")
  }
  
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  .sticky.content {
    padding-top: 102px;
  }
  
  .header {
    padding: 10px 16px;
    background : #d4edda;
    background: #555;
    color: #f1f1f1;
    z-index: 999999;
  }
  