.App {
    text-align: center;
    -webkit-user-select: none;
  
  }
  

  body{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    -ms-scroll-chaining:none;
  
        overscroll-behavior:contain
  }
  
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  
  label.col-form-label{
    text-align: right
  }
  
  
  .form-control.line{
    color : blue;
  
    /* border-radius : 0;
    border: 0px;
    border-bottom: 1px solid #ced4da; */
  }
  
  
  .form-control{
    color : blue;
  /* 
    border-radius : 0;
    border: 0px;
      border-bottom: 1px solid #ced4da; */
  }
  
  .checked {
    color: orange;
  }
 
  @media (min-width:  300px) { 
    .fa-star {
      font-size: 1.4em;
      margin-left: 10px
    }
  
  
   }

   @media (min-width: 576px) { 

 
    .fa-star {
      font-size: 1.4em;
      margin-left: 16px
    }
    }

  
  .top-container {
    background-color: #f1f1f1;
    padding: 30px;
    text-align: center;
    color: white;
    
    background-image: url("https://images.wallpaperscraft.com/image/forest_trees_nature_79434_1280x1024.jpg")
  }
  
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  .sticky.content {
    padding-top: 102px;
  }
  
  .header {
    padding: 10px 16px;
    background : #d4edda;
    background: #555;
    color: #f1f1f1;
    z-index: 999999;
  }
  
.LoginForm {
  /* background-image: url('/src/static/images/blur-26347-27038-hd-wallpapers.jpg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 10px;
  min-height: 100vh;
} 

.form-heading {
  color: #fff;
  font-size: 23px;
}

.panel h2 {
  color: #444444;
  font-size: 18px;
  margin: 0 0 8px 0;
}

.panel p {
  color: #777777;
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 24px;
}

.login-form .form-control {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
}

.main-div {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 15px;
  margin: 10px auto 30px;
  /* max-width: 38%; */
  /* padding: 50px 70px 70px 71px; */
  padding:20px;
}

.login-form .form-group {
  margin-bottom: 10px;
}

.login-form {
  text-align: center;
}

.forgot a {
  color: #777777;
  font-size: 14px;
  text-decoration: underline;
}

.login-form .btn.btn-primary {
  background: #f0ad4e none repeat scroll 0 0;
  border-color: #f0ad4e;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0;
}

.forgot {
  text-align: left;
  margin-bottom: 30px;
}

.botto-text {
  color: #ffffff;
  font-size: 14px;
  margin: auto;
}

.login-form .btn.btn-primary.reset {
  background: #ff9900 none repeat scroll 0 0;
}

.back {
  text-align: left;
  margin-top: 10px;
}

.back a {
  color: #444444;
  font-size: 13px;
  text-decoration: none;
}
